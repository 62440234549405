import layersWMS from '../data/layers/layersconfig.json';
import layersEOdyn from '../data/layers/layersconfig_eOdyn.json';
import * as maptalks from 'maptalks';
//import proj4 from 'proj4';
import {WindyLayer} from "./maptalks.windylayer.js";
//const WindyLayer = require('maptalks.windylayer').WindyLayer;
//import * as echarts from 'echarts'
const { E3Layer } = require('maptalks.e3');



export function getDaysInMonth(month: number, year: number) {
    // Attention : le mois est 0-indexé en JavaScript (0 pour janvier, 1 pour février, etc.)
    return new Date(year, month, 0).getDate();
}

export const monthNames = ["","Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export interface LayerConfigWMS {
    id: string;
    name: string;
    longname: string;
    urlTemplate: string;
    layers: string;
    service: string;
    version: string;
    dateformat: string;
    styles: string;
    colorScaleRange: string;
    opacity: number;
    srs: string;
}

export function getLayerConfigWMS(id: string): LayerConfigWMS | null {
    var layerConfig: LayerConfigWMS | null = null;
    layersWMS.forEach(layer => {
        if (layer.id === id) {
            console.log(layer)
            layerConfig = layer;
        }
    });
    return layerConfig;
}

export interface LayerConfigEOdyn {
    id: string;
    name: string;
    path: string;
    tag: string;
}

export function getLayerConfigEOdyn(id: string): LayerConfigEOdyn | null {
    var layerConfig: LayerConfigEOdyn | null = null;
    layersEOdyn.forEach(layer => {
        if (layer.id === id) {
            console.log(layer)
            layerConfig = layer;
        }
    });
    return layerConfig;
}

export interface SelectedDate {
    year: number;
    month: number;
    day: number;
    hour: number;
};

export function wmsDateString(date: SelectedDate): string {
    return new Date(Date.UTC(
        date.year,
        date.month - 1,
        date.day, 12
    )).toISOString();
}

export function getTimeFormatWMS(date: string, dateformat: string): string {
    var timeFormat;
        if (dateformat === 'ms') {
            // Format ISO 8601 complet
            timeFormat = date;
        } else if (dateformat === 'day') {
            // Format uniquement avec la date (YYYY-MM-DD)
            timeFormat = date.split('T')[0];
        } else if (dateformat === 'dms') {
            timeFormat = date.split('T')[0] +'T00:00:00.000Z';
        } else {// Format par défaut
            timeFormat = date.split('-')[0] + '-' + date.split('-')[1] + '-01T00:00:00.00Z';
        }
    return timeFormat;
}

export function buildWmsUrl(layerData: LayerConfigWMS, timeFormat: string): string {
    const baseUrl = layerData.urlTemplate;
    const params = new URLSearchParams({
        //layers: layerData.layers,
        //styles: layerData.styles,
        //format: 'image/png',
        //transparent: 'true',
        //version: layerData.version,
        //srs: layerData.srs,
        //width: '256',
        //height: '256',
        colorscalerange: layerData.colorScaleRange,
        time: timeFormat,
    });

    return `${baseUrl}?${params.toString()}`;
}

export function min(a:number, b:number): number {
    if (a < b) return a;
    else return b;
}

export function julianToDate(julian: number): Date {
    // Convertit un jour julien en date
    var date = new Date();
    date.setTime((julian - 2440587.5) * 86400000);
    return date;
}

export function createMarkersLayer(jsonData: any, layerId: string, date: Date): maptalks.VectorLayer{
    var vectorLayer = new maptalks.VectorLayer(layerId);
    var dateref = new Date(2440000);
    console.log('julian day reference (2440000 23/05/1968): ',dateref.getTime() + ' ' + dateref.toISOString())



    jsonData.forEach(function(item: any) {
        var shipData = item.ship;

        for (var i = 1; i < shipData.length; i += 5) {
            var lon = shipData[i + 1]; //  longitude
            var lat = shipData[i + 2]; //  latitude
            var cap = shipData[i + 3];
            var sog = shipData[i + 4];
            var time = shipData[i]; // Heure 

            //console.log(julianToDate(time).getHours())
            //console.log(date.getHours())
            //console.log("#########################")
            //if (julianToDate(time).getHours() != date.getHours()) continue;

            if (sog > 1) {

                var marker = new maptalks.Marker([lon, lat], {
                    properties: { 'time': time },
                    symbol: {
                        markerType: 'path',
                        // Chemin SVG pour un triangle
                        markerPath: ' M 50,5 95,95 50,70 5,97.5 z', // Un triangle simple  M 50,5 95,95 50,70 5,97.5 z
                        markerPathWidth: 100,
                        markerPathHeight: 100,
                        markerFill: 'rgb(200,0,150)',//'rgb(0,235,250)',//'rgb(200,0,150)', // Couleur de remplissage
                        markerLineColor: '#fff', // Couleur de la ligne
                        markerLineWidth: 15,
                        markerRotation: -cap , // Ajuster la rotation
                        markerWidth: 9,
                        markerHeight: 12
                    
                }
                }).addTo(vectorLayer); 
            }
            else {
                var marker = new maptalks.Marker([lon, lat], {
                    properties: { 'time': time },
                        symbol: {
                        markerType: 'ellipse',
                        markerWidth: 6,
                        markerHeight: 6,
                        markerFill: 'rgb(200,0,150)',
                        markerFillOpacity: 0.7,
                        markerLineColor: '#fff',
                        markerLineWidth: 0.5
                    }
                }).addTo(vectorLayer);
            
            }
            // TODO ATTENTION 
            //// Initialiser le marqueur comme masqué si le temps n'est pas dans la plage du slider
            //if (time != 2459197.0417) {
            //    marker.hide();
            //}
        }
    }); // Fermeture de forEach 
    return vectorLayer
}

export function createEddiesLayer(jsonData: any, layerId: string): maptalks.VectorLayer{
    var vectorLayer = new maptalks.VectorLayer(layerId);

    // Itérer sur chaque objet dans le tableau de données
    jsonData.forEach(function(item: any) {
        var eddy = item.eddy;
        var id = eddy[0]; // Identifiant du tourbillon
        var coordinates = [];

        // Extraire les coordonnées
        for (var i = 1; i < eddy.length; i += 2) {
            coordinates.push([eddy[i], eddy[i + 1]]);
        }

        // Créer un polygone pour le tourbillon
        var polygon = new maptalks.Polygon([coordinates], {
            symbol: {
                'polygonFill': 'rgba(135, 196, 240, 0.5)', // Couleur bleue semi-transparente
                'polygonOpacity': 0.5,
                'lineColor': 'rgb(100,0,200)',
                'lineWidth': 2
            }
        });

        // Ajouter le polygone au layer
        polygon.addTo(vectorLayer);
    });
    return vectorLayer;
}

// Couleurs représentant la palette RdYlBu
const colorMap = [
    { r: 4, g: 90, b: 141 },     // Bleu très foncé
    { r: 49, g: 54, b: 149 },    // Bleu foncé
    { r: 69, g: 117, b: 180 },   // Bleu
    { r: 116, g: 173, b: 209 },  // Bleu clair
    { r: 171, g: 217, b: 233 },  // Cyan clair
    { r: 224, g: 243, b: 248 },  // Jaune clair
    { r: 254, g: 224, b: 144 },  // Jaune-Orange
    { r: 253, g: 174, b: 97 },   // Orange clair
    { r: 244, g: 109, b: 67 },   // Rouge-Orange
    { r: 215, g: 48, b: 39 },    // Rouge
    { r: 165, g: 0, b: 38 }      // Rouge foncé
];

function getColorBySpeed(sog: number, maxSOG: number){
    // Normaliser la valeur de SOG
    var normalizedSpeed = Math.min(Math.max(sog / maxSOG, 0), 1);

    // Calculer l'index dans la palette de couleurs
    var index = Math.floor(normalizedSpeed * (colorMap.length - 1));

    // Sélectionner la couleur correspondante
    var color = colorMap[index];
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
}

export function createDriftersLayer(jsonData: any, layerId: string): maptalks.VectorLayer{
    var vectorLayer = new maptalks.VectorLayer(layerId);


    // Itérer sur chaque élément dans les données des drifters
    jsonData.forEach(function(item: any) {
        var shipData = item.ship;
        var previousPoint = null;

        for (var i = 1; i < shipData.length; i += 5) {
            var lon = shipData[i + 1];
            var lat = shipData[i + 2];
            var sog = shipData[i + 4];
            var currentPoint = [lon, lat];
            var time = shipData[i]; // Heure 


            if (previousPoint) {
                // Créer un segment de polyline
                var color = getColorBySpeed(sog,100); // Utilisez la fonction pour obtenir la couleur
                var segment = new maptalks.LineString([previousPoint, currentPoint], {
                    symbol: {
                        lineColor: color,
                        lineWidth: 3,
                        lineOpacity: 1,
                        //'shadowBlur' : 10,
                                //'shadowOffsetX' : 10,
                                //'shadowOffsetY' : 10,
                                //'shadowColor' : 'black'
                    }
                }).addTo(vectorLayer);
            }

            previousPoint = currentPoint;

            if (i == shipData.length - 5) {
                // Créer un marker à la dernière position
                new maptalks.Marker(currentPoint, {
                    properties: { 'time': time },
                    symbol: {
                                'markerType' : 'ellipse',
                                'markerFill' : {
                                    'type' : 'linear',
                                    'places' : [0, 0, 1, 1],
                                    'colorStops' : [
                                    [0.00, '#fff'],
                                    [0.5, '#FFD700'],
                                    [1, '#AA0700']
                                    ]
                                },
                                'markerLineWidth' : 0,
                                'markerWidth' : 10,
                                'markerHeight' : 10,
                                'shadowBlur' : 15,
                                'shadowOffsetX' : 10,
                                'shadowOffsetY' : 10
                    }
                }).addTo(vectorLayer);
            }
        }
    });

    return vectorLayer;
}

export function createWindyLayer(jsonData: any, layerId: string){
    console.log("createWindyLayer == jsonData:", jsonData);
    var windyLayer = new WindyLayer(layerId, jsonData, { 'opacity' : 1 });
    return windyLayer;
}

function calculateCap(lat1: number, lon1: number, lat2: number, lon2: number) {
    const rad = Math.PI / 180;
    const lat1Rad = lat1 * rad;
    const lat2Rad = lat2 * rad;
    const deltaLonRad = (lon2 - lon1) * rad;
  
    const y = Math.sin(deltaLonRad) * Math.cos(lat2Rad);
    const x = Math.cos(lat1Rad) * Math.sin(lat2Rad) -
              Math.sin(lat1Rad) * Math.cos(lat2Rad) * Math.cos(deltaLonRad);
    const bearingRad = Math.atan2(y, x);
  
    // Convertir le résultat en degrés, ajuster le résultat pour qu'il soit compris entre 0 et 360
    const bearingDeg = (bearingRad / rad + 360) % 360;
  
    return bearingDeg;
  }

  function convertTimestampToTimestampHours(timestamp: number) {
    // Créer un objet Date à partir du timestamp
    const date = new Date(timestamp * 1000);
  
    // Extraire les heures, minutes et secondes
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
  
    // Convertir en secondes depuis minuit
    const secondsSinceMidnight = (hours * 3600) + (minutes * 60) + seconds;
  
    return secondsSinceMidnight;
  }

function getColorByTime(time: number, maxTime: number) {
    // Normaliser la valeur de temps
    var normalizedTime = Math.min(Math.max(time / maxTime, 0), 1);

    // Calculer l'index dans la palette de couleurs
    var index = Math.floor(normalizedTime * (colorMap.length - 1));

    // Sélectionner la couleur correspondante
    var color = colorMap[index];
    return `rgb(${color.r}, ${color.g}, ${color.b})`;
}

function getOpacityByTime(elapsedTime: number, totalTime: number): number {
    // Assurez-vous que elapsedTime ne dépasse pas totalTime
    elapsedTime = Math.min(elapsedTime, totalTime);

    // Calculer la valeur normalisée de temps
    // Plus elapsedTime est petit (récent), plus la valeur normalisée sera proche de 1
    // et inversement, plus elapsedTime est grand, plus la valeur sera proche de 0
    const normalizedTime = 1 - (elapsedTime / totalTime);

    // Vous pouvez ajuster le calcul ci-dessous pour modifier la manière dont l'opacité diminue
    // Par exemple, pour une diminution linéaire de l'opacité :
    const opacity = normalizedTime;

    // Pour une diminution plus douce, vous pourriez vouloir utiliser une courbe, par exemple :
    // const opacity = Math.sqrt(normalizedTime);

    // Assurer que l'opacité est toujours dans l'intervalle [0, 1]
    return Math.max(0, Math.min(opacity, 1));
}

export function createDriftersOpendriftLayer(jsonData: any, layerId: string, lonr: number, latr: number, radius: number): maptalks.VectorLayer{
    var vectorLayer = new maptalks.VectorLayer(layerId);
    var draw_items: any = []

    // Itérer sur chaque élément dans les données des drifters
    jsonData.forEach(function(item: any) {
        var shipData = item.ship;
        var previousPoint = null;
        var previousTime = 0;

        var flon = shipData[2];
        var flat = shipData[3];

        const totalTime = shipData[0];
        //if (haversineDistance(lonr,latr,flon,flat) <= radius){
        if (true) {
            for (var i = 1; i < shipData.length; i += 3) {
                var lon = shipData[i + 1];
                var lat = shipData[i + 2];
                var sog = 49;//shipData[i + 4];
                var currentPoint = [lon, lat];
                var time = shipData[i]/10; // Heure

                if (previousPoint) {
                    
                    // Créer un segment de polyline
                    time = convertTimestampToTimestampHours(time);

                    var color = getColorByTime((i-1)/3,totalTime); // Utilisez la fonction pour obtenir la couleur
                    var opcacity = getOpacityByTime((i-1)/3,totalTime);
               
                    var segment = new maptalks.LineString([previousPoint, currentPoint], {
                        symbol: {
                            lineColor: color,
                            lineWidth: 3,
                            lineOpacity: opcacity,
                            //'shadowBlur' : 10,
                            //'shadowOffsetX' : 10,
                            //'shadowOffsetY' : 10,
                            //'shadowColor' : 'black'
                        }
                    })//.addTo(vectorLayer);
                    draw_items.push(segment)
                }

                previousPoint = currentPoint;
                if (previousTime <= time) {
                    previousTime = time;
                }

                if (i == 1) {//shipData.length - 3) {
                    // Créer un marker à la première position
                    var point = new maptalks.Marker(currentPoint, {
                        properties: { 'time': time },

                        symbol : {
                            markerType: 'pin',
                            markerFill : {
                                'type' : 'linear',
                                'places' : [0, 0, 1, 1],
                                'colorStops' : [
                                [0.00, '#fff'],
                                [0.5, '#FFD700'],
                                [1, '#AA0700']
                                ]
                            },
                            markerFillOpacity: 1,
                            //markerLineColor: '#34495e',
                            markerLineWidth: 3,
                            markerLineOpacity: 1,
                            markerLineDasharray:[],
                            markerWidth: 25,
                            markerHeight: 25,
                            markerDx: 0,
                            markerDy: 0,
                            markerOpacity : 1,
                            markerRotation : calculateCap(lat,lon,shipData[4+2],shipData[4+1]),
                        }
                    })//.addTo(vectorLayer);
                    draw_items.push(point)
                }
            }
        }
    });

    
    vectorLayer.addGeometry(draw_items)
    return vectorLayer;
}


function generateGradientColors(startHue: number, endHue: number, steps: number): string[] {
    let colors: string[] = [];
    for (let i = 0; i < steps; i++) {
        // Calculer la teinte interpolée pour cette étape
        let hue: number = startHue + ((endHue - startHue) * i / (steps - 1));
        // Créer la couleur HSL et la convertir en chaîne de caractères pour ECharts
        colors.push(`hsl(${hue}, 100%, 50%)`);
    }
    return colors;
}

// Fonction pour calculer la distance entre deux points (lon1, lat1) et (lon2, lat2)
function haversineDistance(lon1: number, lat1: number, lon2: number, lat2: number) {
    const R = 6371; // Rayon de la Terre en kilomètres
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c;
    return distance;
}

export function createE3Layer(jsonData: any, layerId: string, lon: number, lat: number, radius: number){
    //console.log(lon)
    //console.log(lat)
    //console.log(radius)
    //let blueToRedGradient = generateGradientColors(240, 360, 10);
    var hStep = 300 / (jsonData.length - 1);
    var layerLines = [].concat.apply([], jsonData.map(function (line: any, idx: any) {
        var prevPt: any;
        var points = [];
        var status = true;
        for (var i = 0; i < line.length; i += 2) {
            if (line[i] !== undefined && line[i + 1] !== undefined && status) {
                var pt = [line[i], line[i + 1]];
                if (i === 0){
                    if (haversineDistance(lon,lat,pt[0],pt[1])<= radius){
                        status = true;
                    } else {
                        status = false;
                        break;
                    }
                }

                if (pt[0] !== undefined && pt[1] !== undefined) {
                    //if (haversineDistance(lon,lat,pt[0],pt[1])<= radius) {
                        points.push([pt[0], pt[1]]);
                    //}
                    
                } else {
                    console.log("Point invalide détecté et ignoré :", pt);
                }
                
            }
        }
        if(points.length<2) return null;
        //let colorIndex = Math.min(Math.floor((blueToRedGradient.length - 1) * idx / (jsonData.length - 1)), blueToRedGradient.length - 1);
        //let lineColor = blueToRedGradient[colorIndex];
        return {
            'coords': points,
            'lineStyle': {
                //'color': "white",//lineColor//echarts.color.modifyHSL('#5A94DF', Math.round(hStep * idx))//Math.round(hStep * idx))
                color: {
                    type: 'linear',
                    colorStops: [
                        {
                            offset: 0, color: 'blue' // Couleur de départ
                        },
                        {
                            offset: 0.5, color: 'yellow' // Couleur de départ
                        },
                        {
                            offset: 1, color: 'red' // Couleur de fin
                        }
                    ],
                    global: false
                },
            }
        };
    }).filter(function(item: any) {
        return item !== null; // Garde seulement les éléments qui ne sont pas `null`
    }));

    var ecOption = {
        series: [ {
            type: 'lines',
            polyline: true,
            data: layerLines,
            silent: true,
            lineStyle: {
                opacity: 0.1,
                width: 1,
            },
            progressiveThreshold: 500,
            progressive: 200,
            effect: {
                constantSpeed: 20,
                show: true,
                trailLength: 0.4,
                symbolSize: 1.5,
                color: 'white',
            },
            zlevel: 1,
        }]
    };

    var e3Layer = new E3Layer(layerId, ecOption, { hideOnZooming : true, hideOnRotating : true, hideOnMoving : true })
 
    return e3Layer



    var vectorLayer = new maptalks.VectorLayer(layerId);

    // Itérer sur chaque élément dans les données des drifters
    jsonData.forEach(function(item: any) {
        var shipData = item.ship;
        var previousPoint = null;
        for (var i = 1; i < shipData.length; i += 4) {
            var time = shipData[i];
            var lon = shipData[i + 1];
            var lat = shipData[i + 2];
            var sog = shipData[i + 3];
            var currentPoint = [lon, lat];

            if (previousPoint) {
                // Créer un segment de polyline
                var color = getColorBySpeed(sog,100); // Utilisez la fonction pour obtenir la couleur
                console.log(color);
                var segment = new maptalks.LineString([previousPoint, currentPoint], {
                    symbol: {
                        lineColor: color,
                        lineWidth: 3,
                        lineOpacity: 1,
                        //'shadowBlur' : 10,
                        //'shadowOffsetX' : 10,
                        //'shadowOffsetY' : 10,
                        //'shadowColor' : 'black'
                    }
                }).addTo(vectorLayer);
            }

            previousPoint = currentPoint;

            if (i == shipData.length - 5) {
                // Créer un marker à la dernière position
                new maptalks.Marker(currentPoint, {
                    properties: { 'time': time },
                    symbol: {
                                'markerType' : 'ellipse',
                                'markerFill' : {
                                    'type' : 'linear',
                                    'places' : [0, 0, 1, 1],
                                    'colorStops' : [
                                    [0.00, '#fff'],
                                    [0.5, '#FFD700'],
                                    [1, '#AA0700']
                                    ]
                                },
                                'markerLineWidth' : 0,
                                'markerWidth' : 10,
                                'markerHeight' : 10,
                                'shadowBlur' : 15,
                                'shadowOffsetX' : 10,
                                'shadowOffsetY' : 10
                    }
                }).addTo(vectorLayer);
            }
        }
    });

    return vectorLayer;
}


class WMSCustomLayer extends maptalks.TileLayer {
    // Surcharge de la méthode getTileUrl pour inclure le calcul du bbox
    getTileUrl(x: any, y: any, z: any) {
      const map = this.getMap();
      const projection = map.getProjection();
      const tileSize = this.getTileSize();
      const res = map.getResolution(z);
      const nwMap = new maptalks.Coordinate(x * tileSize.width * res, -(y + 1) * tileSize.height * res);
      const seMap = new maptalks.Coordinate((x + 1) * tileSize.width * res, -y * tileSize.height * res);
      const nwCoord = projection.unproject(nwMap);
      const seCoord = projection.unproject(seMap);
      console.log("WMSCustomLayer : "+x+" "+y+" "+z)
      console.log("tileSize : "+tileSize.width+" "+tileSize.height)
      console.log("res : "+res)
      console.log("nwMap : "+nwMap)
      console.log("seMap : "+seMap)
      console.log("nwCoord : "+nwCoord.x+" "+nwCoord.y+" "+nwCoord.z)
      console.log("seCoord : "+seCoord.x+" "+seCoord.y+" "+seCoord.z)
      
      // Construction du bbox
      //24.5952,-34.704,30.1248,-29.1744
      const bbox = [nwCoord.x, seCoord.y, seCoord.x, nwCoord.y].join(',');
        console.log(bbox)
        console.log()

      // Construire l'URL de la tuile WMS avec le bbox correct
      return `/ncWMS2/wms?FORMAT=image%2Fpng&TRANSPARENT=TRUE&STYLES=default-vector%2Fdefault&LAYERS=Arrow_miost_aguhlas_AISonly_2019_vjun2022_masque_OS%2Fu%3Av-group&TIME=2019-12-31T00%3A00%3A00.000Z&COLORSCALERANGE=-0.1033%2C2.271&NUMCOLORBANDS=250&ABOVEMAXCOLOR=0x000000&BELOWMINCOLOR=0x000000&BGCOLOR=transparent&LOGSCALE=false&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&SRS=EPSG%3A4326&BBOX=`+bbox+`&WIDTH=256&HEIGHT=256`;
    }
  }


class WMSRasterLayer extends maptalks.TileLayer {
    // Surcharge de la méthode getTileUrl pour inclure le calcul du bbox
    getTileUrl(x: any, y: any, z: any) {
      const map = this.getMap();
      const totalTilesX = Math.pow(2, z);
      const lonPerTile = 360 / totalTilesX;
      const minLon = x * lonPerTile - 180;
      const maxLon = (x + 1) * lonPerTile - 180;
  
      // Calcul correct de la latitude tenant compte du zoom
      const radians = Math.PI / 180;
      const totalTilesY = Math.pow(2, z);
      const mapHeight = totalTilesY * 256; // 256 est la hauteur standard d'une tuile en pixels
      const pixelY = y * 256;
      const pixelYMax = (y + 1) * 256;
  
      // Conversion de pixelY en latitude
      let latRad = Math.atan(Math.sinh(Math.PI * (1 - 2 * pixelY / mapHeight)));
      let maxLat = latRad / radians;
  
      // Conversion de pixelYMax en latitude
      latRad = Math.atan(Math.sinh(Math.PI * (1 - 2 * pixelYMax / mapHeight)));
      let minLat = latRad / radians;
  
      // Assurez-vous que l'ordre des latitudes est correct (minLat avant maxLat)
      if (minLat > maxLat) {
        [minLat, maxLat] = [maxLat, minLat];
      }
  
      const bbox = [minLon, minLat, maxLon, maxLat].join(',');
        console.log(bbox)
        console.log()

      // Construire l'URL de la tuile WMS avec le bbox correct
      return `/ncWMS2/wms?FORMAT=image%2Fpng&TRANSPARENT=TRUE&STYLES=default-vector%2Fdefault&LAYERS=Arrow_miost_aguhlas_AISonly_2019_vjun2022_masque_OS%2Fu%3Av-group&TIME=2019-12-31T00%3A00%3A00.000Z&COLORSCALERANGE=-0.1033%2C2.271&NUMCOLORBANDS=250&ABOVEMAXCOLOR=0x000000&BELOWMINCOLOR=0x000000&BGCOLOR=transparent&LOGSCALE=false&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&SRS=EPSG%3A4326&BBOX=`+bbox+`&WIDTH=256&HEIGHT=256`;
    }
  }

  class CustomWMSTileLayer extends maptalks.WMSTileLayer {
    constructor(id:any, options:any) {
      super(id, options);
    }

    // Surcharge de la méthode getTileUrl pour inclure le calcul du bbox
    getTileUrl(x:any, y:any, z:any) {

        //if (z < 5) return "";
        // Rien faire ou Retourner une tuile transparente 1x1 pixel
        //return 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

        const resolution = 256;
        
        // Conversion des coordonnées x, y en longitude et latitude
        let lon_left = (x / Math.pow(2, z)) * 360 - 180;
        let lon_right = ((x + 1) / Math.pow(2, z)) * 360 - 180;
        
        let n_top = Math.PI - 2 * Math.PI * y / Math.pow(2, z);
        let n_bottom = Math.PI - 2 * Math.PI * (y + 1) / Math.pow(2, z);
        
        let lat_top = (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n_top) - Math.exp(-n_top)));
        let lat_bottom = (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n_bottom) - Math.exp(-n_bottom)));

        const bbox = [lon_left, lat_bottom, lon_right, lat_top].join(',');
    
    console.log("WMSCustomLayer : "+x+" "+y+" "+z)
    console.log(bbox)
    console.log()

    return `${this.options.urlTemplate}&BBOX=${bbox}&SRS=EPSG:4326`;
  }
  
  }


  //https://seaops.eodyn.com/ncWMS2/wms?FORMAT=image%2Fpng&TRANSPARENT=TRUE&STYLES=default-vector%2Fdefault&LAYERS=Arrow_miost_aguhlas_AISonly_2019_vjun2022_masque_OS%2Fu%3Av-group&TIME=2019-12-31T00%3A00%3A00.000Z&COLORSCALERANGE=-0.1033%2C2.271&NUMCOLORBANDS=250&ABOVEMAXCOLOR=0x000000&BELOWMINCOLOR=0x000000&BGCOLOR=transparent&LOGSCALE=false&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&SRS=EPSG%3A4326&BBOX=27.36,-34.704,30.1248,-31.9392&WIDTH=256&HEIGHT=256
  //https://seaops.eodyn.com/ncWMS2/wms?FORMAT=image%2Fpng&TRANSPARENT=TRUE&STYLES=default-vector%2Fdefault&LAYERS=Arrow_miost_aguhlas_AISonly_2019_vjun2022_masque_OS%2Fu%3Av-group&TIME=2019-12-31T00%3A00%3A00.000Z&COLORSCALERANGE=-0.1033%2C2.271&NUMCOLORBANDS=250&ABOVEMAXCOLOR=0x000000&BELOWMINCOLOR=0x000000&BGCOLOR=transparent&LOGSCALE=false&SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&SRS=EPSG%3A4326&BBOX=19.0656,-45.7632,41.184,-23.6448&WIDTH=256&HEIGHT=256


  const formatDate = (date: Date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = (`0${d.getMonth() + 1}`).slice(-2); // Ajoute un zéro devant le mois si nécessaire et extrait les deux derniers caractères
    const day = (`0${d.getDate()}`).slice(-2); // Ajoute un zéro devant le jour si nécessaire et extrait les deux derniers caractères
  
    return `${year}-${month}-${day}`;
  };

export function createRasterLayer(map: maptalks.Map, layerId: string, date: Date) {


    const layerData = getLayerConfigEOdyn(layerId);
    console.log("layerData : ",layerData)

    const str_time = date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDay(); //+"T"+date.getHours()+"%3A"+date.getMinutes()+"%3A"+date.getSeconds()+"Z"
    console.log("date : "+date)
    console.log("str_time : "+str_time)
    console.log("formatDate : "+ formatDate(date))
    const wmsUrl = 'https://ncwms.eodyn.com/ncWMS/wms?TIME='+formatDate(date)+"T00%3A00%3A00.000Z";//"2020-03-31T00%3A00%3A00.000Z";//str_time;
    const wmsLayer = new maptalks.WMSTileLayer(layerId, {
        urlTemplate: wmsUrl,
        crs: 'EPSG:3857',
        layers: 'prod-afs/ewct:nsct-group',
        styles: '',
        format: 'image/png',
        transparent: true,
        version: '1.1.1',
        uppercase: true,
        
    });

    wmsLayer.addTo(map);
}


export function newVectorLayer(map: maptalks.Map, layerId: string, date: Date, endId: string = "" ) {
    const layerData = getLayerConfigEOdyn(layerId);
    if (!layerData) return null;

    if (layerId.includes("OSRasterLayer")) {
        createRasterLayer(map, layerId, date)
        return;
    }
    var path;
    //if (layerId === "markersLayer"){
    //    path = `${layerData.path}${date.toISOString().split('T')[0].replaceAll("-", "")}_${date.getHours().toString()}_${layerData.tag}.json`;
    //} else {
    //    path = `${layerData.path}${date.toISOString().split('T')[0].replaceAll("-", "")}_${layerData.tag}.json`;
    //}

    //if (layerId === "windyLayer"){
    //    path = `/api_eodyn/data/windyLayer/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json`
    //}
    if (layerId === "opendriftDrifterLayer"){
        const layerTarget: any = map.getLayer("target");
        if (layerTarget != null && layerTarget !== undefined) {
            const x = layerTarget.getLastGeometry().getCenter()["x"]
            const y = layerTarget.getLastGeometry().getCenter()["y"]
            const radius = layerTarget.getLastGeometry().toJSON()["radius"]/1000
            path = `/data/${layerId}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json/${x}/${y}/${radius}`
        } else {
            path = `/data/${layerId}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json`
        }
        
    } else if (layerId === "markersLayer"){
        const dateday = date.getHours().toString().padStart(2, '0');
        path = `/data/${layerId}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}_${dateday}/json`
    }else {
        path = `/data/${layerId}/_${layerData.tag}/${date.toISOString().split('T')[0].replaceAll("-", "")}/json`
    }
    
    console.log("path:", path);
    const token = localStorage.getItem('authToken');
    fetch(path, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`, // <-- Add the token here
            'Content-Type': 'application/json'
        }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(`Erreur de chargement du fichier JSON: ${response.statusText}`);
            }
            return response.json();
        })
        .then(jsonData => {
            console.log("jsonData:", jsonData);
            let vectorLayer;

            switch (layerId) {
                case "markersLayer":
                    vectorLayer = createMarkersLayer(jsonData, layerId, date);
                    break;
                case "eddiesLayer":
                    vectorLayer = createEddiesLayer(jsonData, layerId);
                    break;
                case "driftersLayer":
                    vectorLayer = createDriftersLayer(jsonData, layerId);
                    break;
                case "windyLayer":
                    vectorLayer = createWindyLayer(jsonData, layerId);
                    break;
                case "opendriftDrifterLayer":
                    const layerTarget: any = map.getLayer("target");
                    console.log(layerTarget)
                    if (layerTarget != null && layerTarget !== undefined) {
                        //console.log(layerTarget.getLastGeometry().getCenter()["x"]);
                        //console.log(layerTarget.getLastGeometry().getCenter()["y"]);
                        //console.log(layerTarget.getLastGeometry().toJSON()["radius"]/1e4);
                        vectorLayer = createDriftersOpendriftLayer(
                            jsonData,
                            layerId,
                            layerTarget.getLastGeometry().getCenter()["x"],
                            layerTarget.getLastGeometry().getCenter()["y"],
                            layerTarget.getLastGeometry().toJSON()["radius"]/1000,
                        );
                        //vectorLayer = createE3Layer(
                        //    jsonData,
                        //    layerId,
                        //    layerTarget.getLastGeometry().getCenter()["x"],
                        //    layerTarget.getLastGeometry().getCenter()["y"],
                        //    layerTarget.getLastGeometry().toJSON()["radius"]/1000,
                        //);
                    } else {
                        console.log("########### NO TARGET")
                    }
                    //vectorLayer = createDriftersLayer(jsonData, layerId);
                    //vectorLayer = createE3Layer(jsonData, layerId);
                    break;
                //case "OSRasterLayer":
                //    vectorLayer = createRasterLayer(jsonData, layerId, date);
                //    break;
                default:
                    console.log(`Warning: newVectorLayer for id: ${layerId} not yet available`);
                    return null;
            }

            if (vectorLayer) {
                vectorLayer = vectorLayer as maptalks.Layer
                if (endId != "") {
                    vectorLayer.setId(endId)
                }
                map.addLayer(vectorLayer);
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
            var vectorLayer = new maptalks.VectorLayer(layerId);
            vectorLayer.setZIndex(1000);
            map.addLayer(vectorLayer);
        });
}

export function vectorLayerWMS(map: maptalks.Map, layerId: string, date: Date, index: number, endId: string = "" ) {
    const layerData = getLayerConfigWMS(layerId);
    if (layerData) {
        const timeFormat = getTimeFormatWMS(date.toISOString(),layerData.dateformat);
        let layer = new maptalks.WMSTileLayer(layerId, {
            tileSystem: [1,-1,180,90],
            urlTemplate: buildWmsUrl(layerData, timeFormat),
            layers: layerData.layers, // Ajouter la propriété layers ici
            //opacity: 1,//min(layerData.opacity ,0.5),
            //transparent: true,
            //crs: layerData.srs//'EPSG:4326', // TODO cause disfonctionement other
            
            styles: layerData.styles,
            format: 'image/png',
            transparent: true,
            version: layerData.version,
            crs: layerData.srs,
            
            
        });
        layer.setZIndex(index);
        //layer.setOptions({ opacity: 0.5 });
        if (endId != "") {
            layer.setId(endId)
            console.log("vectorLayerWMS:setId")
        }
        map.addLayer(layer);
        console.log("vectorLayerWMS:getLayerConfigWMS: ADD")
    }
}  





//const service = new maptalks.esri.FeatureLayerService({
//    url: 'https://services.arcgis.com/nGt4QxSblgDfeJn9/ArcGIS/rest/services/Firefly_Grid/FeatureServer/1',
//    visible: false
//});
//
//const gridLayer = new maptalks.VectorLayer('gridLayer').addTo(map);



//const referencesLayer = new maptalks.TileLayer('referencesLayer', {
//    urlTemplate: 'https://server.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Reference/MapServer/tile/{z}/{y}/{x}',
//    subdomains: ["a","b","c","d"],
//    cssFilter : 'grayscale(100%) brightness(400%)',
//    visible: false
//    
//});
//
//map.addLayer(referencesLayer);
